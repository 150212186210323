




import Vue from 'vue';
import store from '@/store';
import { NotificationInterface, SingleNotificationType } from '@/store/states/Notifications/interfaces';
import { PropType } from 'vue';

export default Vue.extend({
  name: 'single-notification',
  data() {
    return {
      route: null,
      content: null,
      date: null,
    };
  },
  computed: {
    isAdmin(): boolean {
      return store.getters.user.isAdmin;
    },
    current(): SingleNotificationType {
      return store.getters.notifications.getNotificationContent(this.notification, store.getters.user.isAdmin);
    },
  },
  methods: {
    deleteNotification(id: string): void {
      store.dispatch.notifications.deleteNotification(id);
    },
    markRead(id: string): void {
      store.dispatch.notifications.markNotificationRead(id);
    },
  },
  props: {
    notification: {
      type: Object as PropType<NotificationInterface>,
      required: true,
    },
  },
});
