var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.current)?_c('v-list-item',[(_vm.current.content)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"list-content"},[_c('div',{staticClass:"list-title"},[_c('router-link',{attrs:{"to":_vm.current.route}},[_vm._v(_vm._s(_vm.current.linkText))])],1),_c('div',[_vm._v(_vm._s(_vm.current.content))]),_c('div',{staticClass:"secondary-text"},[_vm._v(_vm._s(_vm.current.time))])]),_c('div',[_c('div',{staticClass:"buttons-container"},[(hover)?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({on:{"click":function($event){return _vm.deleteNotification(_vm.notification.id)}}},'button',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove notification")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.notification.readStatusId != 2)?_c('button',_vm._g(_vm._b({staticClass:"read-notification",on:{"click":function($event){return _vm.markRead(_vm.notification.id)}}},'button',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-circle-slice-8")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Mark as read")])])],1)])])]}}],null,false,98725769)}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }