




import Vue from 'vue';
import { vuetifyComponents } from '@/plugins/vuetify';
import VueMeta from 'vue-meta';
import { mapState } from 'vuex';
import NotificationCenter from '@/components/notifications/NotificationCenter.vue';
import backend from '@/services/3xrCom';
import sidebarLinks, { SideLink } from '@/components/navigation/sidebar';
import store from '@/store/index';
import PrivacyDialog from '@/components/privacy-dialog/PrivacyDialog.vue';

Vue.use(VueMeta);

export default Vue.extend({
  name: 'App',
  components: {
    ...vuetifyComponents,
    NotificationCenter,
    PrivacyDialog,
  },
  computed: {
    authenticated: () => store.getters.user.authenticated,
    isAdmin: () => store.getters.user.isAdmin,
    role: () => store.getters.user.role,
    currentSidebar(): SideLink[] {
      // filtered sidebar based on user role
      return sidebarLinks.filter((item) => item.roles.includes(this.role));
    },
    ...mapState({
      pickList: (state: any) => state.pickList.pickList,
      user: (state: any) => state.user.user,
    }),
  },
  async created() {
    // Note: first notifications fetch moved to UserState.ts
    // poll notifications every 5min. Remove once websockets are implemented (only if logged in)
    this.polling = window.setInterval(() => {
      if (this.isPageActive) {
        store.dispatch.notifications.fetchNotifications();
      }
    }, 300000);
  },
  mounted(): void {
    // Set the name of the hidden property and the change event for visibility
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      this.pageHidden = 'hidden';
      this.pageVisibility = 'visibilitychange';
    } else if (typeof (document as any).msHidden !== 'undefined') {
      this.pageHidden = 'msHidden';
      this.pageVisibility = 'msvisibilitychange';
    } else if (typeof (document as any).webkitHidden !== 'undefined') {
      this.pageHidden = 'webkitHidden';
      this.pageVisibility = 'webkitvisibilitychange';
    }

    // Handle page visibility change
    if (this.pageVisibility) {
      window.addEventListener(this.pageVisibility, this.handleVisibilityChange);
    }
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  data: () => ({
    dropdown_notifications: ['QA review', 'New Model Assigned', 'Model Approved'],
    footerLinks: [
      { label: 'HOME', url: '/', public: true, visibleLoggedIn: true },
      { label: 'THE PLATFORM', url: '/platform', public: true, visibleLoggedIn: false },
      {
        label: 'HELP & FAQ',
        url: '/artist/resources/help',
        public: false,
        visibleLoggedIn: true,
      },
      { label: 'LOGIN', url: '/login', public: true, visibleLoggedIn: false },
    ],
    navLinks: [],
    searchString: '',
    showDrawer: true,
    polling: 0,
    pageVisibility: '',
    pageHidden: '',
    isPageActive: true,
  }),
  methods: {
    async changePrimaryRole(roleId: number): Promise<void> {
      try {
        const response = await backend.post('admin/account/set_primary_role', { primaryRoleId: roleId });
        if (response.data == 'success') {
          store.dispatch.user.setPrimaryRole(roleId);
        }
      } catch (error) {}
    },
    async logout(): Promise<void> {
      return store.dispatch.user.logout(this.$router.currentRoute);
    },
    search(): void {
      if (this.$router.currentRoute.fullPath != '/search?q=' + encodeURIComponent(this.searchString)) {
        this.$router.push('/search?q=' + encodeURIComponent(this.searchString));
        this.searchString = '';
      }
    },
    handleVisibilityChange(): void {
      if ((document as any)[this.pageHidden]) {
        // page is hidden (another browser tab is active)
        this.isPageActive = false;
      } else {
        this.isPageActive = true;
      }
    },
  },
});
