




import store from '@/store/index';
import { mapState, mapGetters } from 'vuex';
import SingleNotification from './SingleNotification.vue';

export default {
  name: 'notification-center',
  components: {
    SingleNotification,
  },
  computed: {
    ...mapState({
      user: (state: any) => state.user.user,
      notifications: (state: any) => state.notifications.notifications,
    }),
    ...mapGetters('notifications', [
      'isAllNotificationsDone',
      'isJobUpdatedToClientQA',
      'isProjectCreated',
      'isJobCommentAddedArtist',
      'isJobCommentAddedClient',
    ]),
    pendingNotifications: () => store.getters.notifications.pendingNotifications,
    userRole: () => store.getters.user.role,
  },
  methods: {
    markAllRead() {
      store.dispatch.notifications.markAllRead();
    },
    clearAll() {
      store.dispatch.notifications.clearAll();
    },
  },
};
